<template>
  <div class="project-sales-booking-info">
    <div class="title-section row justify-content-between p-3 mx-1">
      <!-- Booking ID -->
      <div class="col-12 sm-col-6 mb-2 sm-mb-0">
        <h2 class="d-flex align-items-center col-12 sm-col-6">Booking</h2>
        <chip class="mt-1">Sale ID: {{ saleId }}</chip>
      </div>
      <!-- Status -->
      <div class="col-12 sm-col-6 mb-2 sm-mb-0 text-left sm-text-right">
        <span
          class="booking-status"
          :class="booking.status ? 'paid' : 'unpaid'"
        >
          {{ booking.status }}
        </span>
        <span
          class="payment-status ml-1"
          :class="booking.status ? 'paid' : 'unpaid'"
        >
          {{ booking.isPaid ? "Paid" : "Unpaid" }}
        </span>
      </div>
      <!-- Submit and Due Date -->
      <div class="col-12 md-col-6 mt-3">
        <display-data
          label="Booking Submit Date"
          :content="$moment(booking.createdAt).format('LL')"
        >
        </display-data>
        <display-data
          label="Payment Due Date"
          :content="$moment(booking.paymentDueDate).format('LL')"
        >
        </display-data>
      </div>
      <!-- Documents -->
      <booking-documents
        class="col-12 md-col-6 mt-3"
        :booking="booking"
        :downloadFileAPI="downloadFileAPI"
      ></booking-documents>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    Chip: () => import("@/components/GlobalComponents/Chip"),
    BookingDocuments: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/BookingDocuments"
      )
  },
  mixins: [],
  props: {
    booking: {
      type: Object,
      default: null
    },
    saleId: {
      type: [Number, String],
      required: true
    },
    downloadFileAPI: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-sales-booking-info {
  .title-section {
    .booking-status {
      background: lighten($color-secondary, 45);
      color: $color-secondary;
      padding: 6px 18px;
      border-radius: 10em;
    }
    .payment-status {
      padding: 6px 18px;
      border-radius: 10em;
      &.paid {
        background: lighten($color-warn, 40);
        color: $color-warn;
      }
      &.unpaid {
        background: lighten($color-success, 45);
        color: $color-success;
      }
    }
  }
}
</style>
